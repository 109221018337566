import React from 'react';
import "../styles/Flow.css";

const FlowItem = ({ item }) => (
    <div className={`flow-item ${item.orientation}`}>
        {item.type === 'text' ? (
            <div className="flow-content">
                <h3 className='flow-content-title'>{item.title}</h3>
                <p className='flow-content-text'>{item.text}</p>
            </div>
        ) : (
            <div className="flow-graphic">
                <img src={item.graphic} alt="" />
            </div>
        )}
    </div>
);

const Flow = () => {
    const flowItems = [
        { number: 1, orientation: 'left', type: 'text', title: 'Register', text: 'Simply start the app and create an account.' },
        { number: 1, orientation: 'right', type: 'graphic', graphic: `${process.env.PUBLIC_URL}/assets/images/register.jpg` },
        { number: 2, orientation: 'left', type: 'graphic', graphic: `${process.env.PUBLIC_URL}/assets/images/eating.jpg` },
        { number: 2, orientation: 'right', type: 'text', title: 'Eat like normal', text: 'Eat and record your items, it is okay to miss a day.' },
        { number: 3, orientation: 'left', type: 'text', title: 'Track progress', text: 'See your results anytime of day.' },
        { number: 3, orientation: 'right', type: 'graphic', graphic: `${process.env.PUBLIC_URL}/assets/images/journal.jpg` },
    ];

    const groupedItems = flowItems.reduce((acc, item) => {
        acc[item.number] = acc[item.number] || [];
        acc[item.number].push(item);
        return acc;
    }, {});

    return (
        <div className='flow-environment'>
            <div className="flow-container">
                <div className='flow'>
                    <div className='flow-title'>How Track Works</div>
                    {Object.values(groupedItems).map((group, index) => (
                        <div key={index} className="flow-row">
                            {group.map((item, index) => (
                                <FlowItem key={index} item={item} />
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Flow;