import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faPlay } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "../styles/Hero.css";

const Hero = () => {
    const navigate = useNavigate();
    const [goUp, setGoUp] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const handleStartClick = () => {
        window.location.href = "https://track-fit.me";
    };

    useEffect(() => {
        const onPageScroll = () => {
            if (window.scrollY > 600) {
                setGoUp(true);
            } else {
                setGoUp(false);
            }
        };
        window.addEventListener("scroll", onPageScroll);

        return () => {
            window.removeEventListener("scroll", onPageScroll);
        };
    }, []);

    return (
        <div className="section-container">
            <div className="hero-section">
                <div className="text-section">
                    <p className="text-headline">Health first</p>
                    <h2 className="text-title">
                        Track your Macros,<br />
                        Reach your Goals
                    </h2>
                    <p className="text-descritpion">
                        Track macros with a database of 400,000+ items. Popular brands,
                        common house-hold items, etc. Create mealplans and stick to your goals.
                    </p>
                    <button
                        className="text-start-btn"
                        type="button"
                        onClick={handleStartClick}
                    >
                        <FontAwesomeIcon icon={faPlay} className="icon-right-margin" /> Start Now!
                    </button>
                    <div className="text-stats">
                        <div className="text-stats-container">
                            <p>400k+</p>
                            <p>Food Items</p>
                        </div>

                        <div className="text-stats-container">
                            <p>Unlimited</p>
                            <p>Meal Creation</p>
                        </div>

                        <div className="text-stats-container">
                            <p>Complete</p>
                            <p>Record Tracking</p>
                        </div>
                    </div>
                </div>

                <div className="hero-image-section">
                    <img className="hero-image1" src={`${process.env.PUBLIC_URL}/assets/images/exercise.png`} alt="Exercise" />
                </div>
            </div>

            <div
                onClick={scrollToTop}
                className={`scroll-up ${goUp ? "show-scroll" : ""}`}
            >
                <FontAwesomeIcon icon={faAngleUp} />
            </div>
        </div>
    );
}

export default Hero;