import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const InformationCard = (props) => {
    return (
        <div className="info-cards">
            <FontAwesomeIcon className="info-fa-icon" icon={props.icon} />
            <h4 className="info-card-title">{props.title}</h4>
            <p className="info-card-description">{props.description}</p>
        </div>
    );
}

export default InformationCard;