import React, { useState } from 'react';
import "../styles/Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
    const [showOverlay, setShowOverlay] = useState(false);
    const [overlayContent, setOverlayContent] = useState("");
    const [overlayTitle, setOverlayTitle] = useState("");

    const handleOpenOverlay = async (fileName) => {
        let title = fileName === 'privacy_policy.txt' ? "Privacy Policy" : "Terms of Service";

        try {
            const response = await fetch(`/assets/legal/${fileName}`);
            const text = await response.text();
            const content = text.split('\n').map((line, index) => (
                <p key={index} className='file-content'>{line}</p>
            ));
            setOverlayContent(content);
            setOverlayTitle(title);
            setShowOverlay(true);
        } catch (error) {
            console.error('Error fetching document:', error);
            setOverlayContent("Failed to load content.");
            setOverlayTitle(title);
            setShowOverlay(true);
        }
    };

    return (
        <>
            <footer className="footer">
                <div className="footer-content">
                    <div className="footer-logo">
                        <div className="logo-container">
                            <img className='footer-logo-image' src={`${process.env.PUBLIC_URL}/logo192.png`} alt="Logo" />
                            <span>TRACK</span>
                        </div>
                    </div>
                    <div className="footer-links">
                        <a href="#!" onClick={() => handleOpenOverlay('privacy_policy.txt')}>Privacy Policy</a> |
                        <a href="#!" onClick={() => handleOpenOverlay('terms.txt')}>Terms</a> |
                        © 2024 PhoenixApps
                    </div>
                </div>
            </footer>
            {showOverlay && (
                <div className="overlay">
                    <div className="overlay-content">
                        <div className="overlay-header">
                            <div className="close-button-container">
                                <button className="close-button" onClick={() => setShowOverlay(false)}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            </div>
                            <h2>{overlayTitle}</h2>
                        </div>
                        <div className="overlay-scrollable-content">
                            <p>{overlayContent}</p>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Footer;
