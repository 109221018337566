import React from 'react';
import { useLocation } from "react-router-dom";
import Header from '../components/Header';
import Footer from '../components/Footer';
import "../styles/NotFound.css";

const NotFound = () => {
    const deployedURL = window.location.href;
    const location = useLocation();

    return (
        <div className="not-found-container">
            <Header />
            <div className="content">
                <h1>Page Not Found</h1>
                <p>The requested URL {deployedURL} was not found on this server.</p>
                <p>The requested URL {location.pathname} was not found on this server.</p>
            </div>
            <Footer />
        </div>
    );
}

export default NotFound;