import React from "react";
import InfoCard from "./InfoCard";
import { faWeight, faSearch, faPlateWheat, faBarcode, faChartSimple, faTabletScreenButton } from "@fortawesome/free-solid-svg-icons";
import "../styles/Info.css";

const Info = () => {
    return (
        <header className="info">
            <div>
                <h3 className="info-title">Why Use Track?</h3>
                <h2 className="info-subtitle">Built with health in mind. Built with passion.</h2>
            </div>
            <div className="info-cards-content">
                <InfoCard
                    title="Database"
                    description="Over 400K+ commonly used items in our database."
                    icon={faSearch}
                />

                <InfoCard
                    title="Goal Tracking"
                    description="Stay on top of goals with recommended intakes."
                    icon={faWeight}
                />

                <InfoCard
                    title="Create Custom Meals"
                    description="Combine any items together for a custom meal."
                    icon={faPlateWheat}
                />

                <InfoCard
                    title="Auto Input"
                    description="Scan any barcode and immediately add it to your journal."
                    icon={faBarcode}
                />

                <InfoCard
                    title="Reporting"
                    description="Extensive reporting on all macro tracking."
                    icon={faChartSimple}
                />

                <InfoCard
                    title="Responsive Apps"
                    description="Available on desktop, iOS and Android."
                    icon={faTabletScreenButton}
                />
            </div>
        </header>
    );
};

export default Info;
