import React from 'react';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Info from '../components/Info';
import Flow from '../components/Flow';
import Footer from '../components/Footer';

const Home = () => {
    return (
        <div className="home-section">
            <Header />
            <Hero />
            <Info />
            <Flow />
            <Footer />
        </div>
    );
}

export default Home;