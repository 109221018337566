import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import "../styles/Header.css";

const Header = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    const handleStartClick = () => {
        window.location.href = "https://track-fit.me";
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <header className={`header ${isScrolled ? 'header-scrolled' : ''}`}>
            <div className="header-content">
                <h1 className="header-title">Track</h1>
                <button className={`start-button ${isScrolled ? 'button-scrolled' : ''}`} onClick={handleStartClick}><FontAwesomeIcon icon={faPlay} className="icon-right-margin" />Start Now!</button>
            </div>
        </header>
    );
};

export default Header;
